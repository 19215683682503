<template>
  <div class="d-flex justify-content-center">
    <b-card title="Announcement Banner" class="w-75">
      <p class="mb-2">
        Set an announcement banner to be shown for all users when they next log in or refresh their browser.
        An empty message will not be shown.
      </p>

      <b-form-group
        label="Message"
        label-for="banner-message"
      >
        <b-form-input
          id="banner-message"
          v-model="bannerMessage"
          placeholder="Enter an announcement message"
          autocomplete="off"
          autocapitalize="sentences"
          spellcheck="true"
          data-cy="bannerMessage"
        />
      </b-form-group>

      <div class="d-inline-flex w-100 justify-content-between">
        <b-form-group
          label="Expiry"
          label-for="banner-expiry"
          class="w-100"
        >
          <flat-pickr
            v-model="bannerExpiry"
            placeholder="Select an expiry time"
            class="form-control w-25"
            :config="{ enableTime: true, minDate: new Date(), dateFormat: 'Y-m-d H:i' }"
            :disabled="isBannerEternal"
          />
        </b-form-group>

        <b-form-checkbox
          v-model="isBannerEternal"
          class="custom-control-info mt-2"
          switch
          @change="setNeverExpire"
        >
          <span class="text-nowrap">
            Never expire
          </span>
        </b-form-checkbox>
      </div>

      <div class="d-inline-flex w-100 justify-content-between mt-2">
        <b-button variant="outline-danger" data-cy="bannerClear" @click="clearBanner">
          Clear Banner
        </b-button>
        <b-button variant="success" data-cy="bannerSubmit" @click="setBanner">
          Set Banner
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onMounted, ref } from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'AdminBanner',
  components: { flatPickr },
  setup(props, context) {
    onMounted(() => { fetchExistingBanner() })

    const loading = ref(false)
    const bannerMessage = ref('')
    const bannerExpiry = ref('')
    const isBannerEternal = ref(true)

    const setNeverExpire = neverExpire => {
      if (neverExpire) { bannerExpiry.value = '' }
    }
    const fetchExistingBanner = () => {
      loading.value = true
      coreService.adminCliApi.getBanner()
        .then(response => {
          if (response) {
            bannerMessage.value = response.message
            bannerExpiry.value = response.expiry
          }
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch banner details',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loading.value = false })
    }
    const setBanner = () => {
      coreService.adminCliApi.updateBanner(bannerMessage.value, bannerExpiry.value)
        .then(response => {
          fetchExistingBanner()
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Announcement banner set',
              text: 'Refresh the page to see changes',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to set Announcement banner',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loading.value = false })
    }
    const clearBanner = () => {
      loading.value = true
      bannerMessage.value = ''
      isBannerEternal.value = true
      coreService.adminCliApi.deleteBanner()
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Announcement banner cleared',
              text: 'Refresh the page to see changes',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to clear Announcement banner',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loading.value = false })
    }

    return {
      bannerMessage,
      bannerExpiry,
      isBannerEternal,

      setNeverExpire,
      setBanner,
      clearBanner,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
